import React from "react";
import { useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  basicGradientDiv: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    background: "rgb(30,90,96)",
    background:
      "linear-gradient(0deg, rgba(19,50,52,1) 0%, rgba(20,46,48,1) 10%, rgba(20,46,48,1) 18%, rgba(25,42,43,1) 28%, rgba(34,34,34,1) 80%, rgba(34,34,34,1) 100%)",
    height: "100%",
    display: "flex",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      minHeight: "100vh"
    }
  }
}));

const BasicGradient = ({ children, style = {} }) => {
  const classes = useStyles();

  return (
    <div className={classes.basicGradientDiv} style={{ ...style }}>
      {children}
    </div>
  );
};
export default BasicGradient;
