import React, { useState, useCallback } from "react";
import LandingBottomTemplate from "./component/LandingBottomTemplate";
import imageOne from "../../../../images/landingSwipeOne.png";
import imageTwo from "../../../../images/landingSwipeTwo.png";
import imageThree from "../../../../images/landingSwipeThree.png";
import BasicWhite from "../../../../utils/BasicWhite";
import { Grid, Hidden, ButtonBase, useTheme } from "@material-ui/core";
import { useTransition, animated } from "react-spring";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  loadingBottomTransDiv: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    minWidth: "100%",
    minHeight: 550
  },
  landingBottomWrapDiv: {
    position: "relative"
  },
  bottomLangingWrapWidth: {
    width: "100%",
    maxWidth: 2000,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.only("md")]: {
      paddingLeft: theme.spacing(26),
      paddingRight: theme.spacing(26)
    },
    [theme.breakpoints.only("sm")]: {
      paddingLeft: theme.spacing(13),
      paddingRight: theme.spacing(13)
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0px auto"
    }
  },
  gridContainerLandingBottom: {
    minHeight: 810,
    boxShadow: "0px 20px 38px #00000014",

    [theme.breakpoints.down("xs")]: {
      minHeight: 750
    },
    [theme.breakpoints.down(450)]: {
      minHeight: 600
    },
    [theme.breakpoints.down(400)]: {
      minHeight: 500
    }
  }
}));

const components = [
  ({ style }) => (
    <animated.div
      style={{
        ...style,
        willChange: "transform, opacity",
        height: "100%",
        width: "100%"
      }}
    >
      <LandingBottomTemplate
        title="Save and Optimize"
        subtitle="Pick the best vendors that adapt to your needs and budget or reuse your existing devices."
        img={imageOne}
        imgPos="center"
      />
    </animated.div>
  ),
  ({ style }) => (
    <animated.div
      style={{
        ...style,
        willChange: "transform, opacity",
        height: "100%",
        width: "100%"
      }}
    >
      <LandingBottomTemplate
        title="Automate"
        subtitle="Create actions and Smart Rules that can interact with any device on the IoT."
        img={imageTwo}
        imgPos="center"
      />
    </animated.div>
  ),
  ({ style }) => (
    <animated.div
      style={{
        ...style,
        willChange: "transform, opacity",
        height: "100%",
        width: "100%"
      }}
    >
      <LandingBottomTemplate
        title="React quicker"
        subtitle="Because of its predictive capabilities grow or reduce your business according to future demands. "
        img={imageThree}
        imgPos="bottom"
      />
    </animated.div>
  )
];

const LandingBottom = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const [inOut, setInOut] = useState(0);
  const [toggle, setToggle] = useState(false);
  const onClickF = useCallback(() => {
    setInOut(0);
    setIndex(state => (state + 1) % 3);
  }, []);
  const onClickB = useCallback(() => {
    setInOut(1);
    setIndex(state => (state === 0 ? 2 : state - 1));
  }, []);
  const transitions = useTransition(index, p => p, {
    from: {
      opacity: 0,
      transform:
        inOut === 0 ? "translate3d(100%,0,0)" : "translate3d(-100%,0,0)",
      position: "absolute"
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0%,0,0)",
      position: "relative"
    },
    leave: {
      opacity: 0,
      transform: inOut === 0 ? "translate3d(-50%,0,0)" : "translate3d(50%,0,0)",
      position: "absolute"
    }
  });

  return (
    <BasicWhite>
      <div className={classes.bottomLangingWrapWidth}>
        <div className={classes.landingBottomWrapDiv}>
          <Hidden mdDown>
            <Grid container>
              <LandingBottomTemplate
                title="Save and Optimize"
                subtitle="Pick the best vendors that adapt to your needs and budget or reuse your existing devices."
                img={imageOne}
                imgPos="center"
              />
              <LandingBottomTemplate
                title="Automate"
                subtitle="Create actions and Smart Rules that can interact with any device on the IoT."
                img={imageTwo}
                imgPos="center"
              />
              <LandingBottomTemplate
                title="React quicker"
                subtitle="Because of its predictive capabilities grow or reduce your business according to future demands. "
                img={imageThree}
                imgPos="bottom"
              />
            </Grid>
          </Hidden>

          <Hidden lgUp>
            {/* <Fade in={!toggle}> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative"
              }}
            >
              <Grid
                container
                justify="center"
                className={classes.gridContainerLandingBottom}
              >
                <div className={classes.loadingBottomTransDiv}>
                  {transitions.map(({ item, props, key }) => {
                    const Page = components[item];
                    return <Page key={key} style={props} />;
                  })}
                </div>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20
                }}
              >
                <div style={{ width: "fit-content" }}>
                  <ButtonBase onClick={onClickB} style={{ marginRigth: 30 }}>
                    <ArrowBackIosRoundedIcon
                      style={{ color: theme.palette.secondary.main }}
                    />
                  </ButtonBase>
                  <ButtonBase onClick={onClickF} style={{ marginLeft: 30 }}>
                    <ArrowForwardIosRoundedIcon
                      style={{ color: theme.palette.secondary.main }}
                    />
                  </ButtonBase>
                </div>
              </div>
            </div>
          </Hidden>
        </div>
      </div>
    </BasicWhite>
  );
};

export default LandingBottom;
