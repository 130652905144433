import React from "react";
import { Typography } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/styles";
import { NavLink, Link } from "react-router-dom";
import CompWrapperWidth from "../../utils/CompWrapperWidth";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
const useStyles = makeStyles(theme => ({
  footerWrapDiv: {
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    maxWidth: 2000,
    margin: "0px auto"
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.sub,
    marginRight: theme.spacing(4)
  },
  typoLinkFooter: {
    fontWeight: 300,
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2)
    }
  },
  footerHashple: {
    fontWeight: "bold"
  },
  linksSocialDiv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: theme.spacing(6),
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start"
    },

    width: "100%"
  },
  linksDiv: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  footerHashpleDiv: {
    marginBottom: theme.spacing(4)
  },
  social: {
    textDecoration: "none",
    color: theme.palette.primary.sub,
    marginRight: theme.spacing(4)
  },
  socialDiv: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(7)
    }
  },
  footerRightsReserved: {
    color: theme.palette.primary.sub,
    fontWeight: 300
  }
}));

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.footerWrapDiv}>
      <div className={classes.footerHashpleDiv}>
        <Typography
          variant="h4"
          className={classes.footerHashple}
          style={{ color: theme.palette.primary.contrastText }}
        >
          HASHPLE
        </Typography>
      </div>
      <div className={classes.linksSocialDiv}>
        <div className={classes.linksDiv}>
          <Typography className={classes.typoLinkFooter}>
            <Link className={classes.link} exact to="/">
              Home
            </Link>
          </Typography>
          <Typography className={classes.typoLinkFooter}>
            <Link className={classes.link} to="/pricing">
              Pricing
            </Link>
          </Typography>
          {/*
          <Typography className={classes.typoLinkFooter}>
            <Link className={classes.link} to="/terms-of-service">
              Terms Of Service
            </Link>
          </Typography>
          <Typography className={classes.typoLinkFooter}>
            <Link className={classes.link} to="/privacy-policy">
              Privacy Policy
            </Link>
          </Typography>
          <Typography className={classes.typoLinkFooter}>
            <Link className={classes.link} to="/faq">
              FAQ
            </Link>
          </Typography>
          */}
        </div>
        {/*
        <div className={classes.socialDiv}>
          <a href="https://www.google.com/" target="_blank">
            <FacebookIcon fontSize="large" className={classes.social} />
          </a>
          <a href="https://www.google.com/" target="_blank">
            <InstagramIcon fontSize="large" className={classes.social} />
          </a>
          <a href="https://www.google.com/" target="_blank">
            <TwitterIcon fontSize="large" className={classes.social} />
          </a>
        </div>
        */}
      </div>
      <div>
        <Typography className={classes.footerRightsReserved}>
          All Rights Reserved @ Copyright 2020 Hashple
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
