import React from 'react'
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  widthWrapDiv: {
    maxWidth: 1600,
    paddingLeft: theme.spacing(18),
    paddingRight: theme.spacing(18),
    [theme.breakpoints.up("lg")]: {
      margin: '0px auto'
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8)
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    },
  }
}));
const NarrowWidthWrap = ({ children, display, style }) => {
  const theme = useTheme()
  const classes = useStyles()
  const styleObj = style ? style : {};
  return (
    <div className={classes.widthWrapDiv} style={{ display: display ? display : "block", ...styleObj }} >
      {children}
    </div>
  )
}
export default NarrowWidthWrap