import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink, Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100vw",


  },
  fullList: {
    width: 'auto',
  },
  drawer: {
  },
  rootDrawer: {
    backgroundColor: "#00000094"

  },
  itemListRoot: {
    '&.active, &:hover, &.active:hover': {
      backgroundColor: "#3ac9d747"
    }
  }
}));


export default function SideDrawer({ state, toggleDrawer}) {
  const classes = useStyles();
  const theme = useTheme()
  const drawerOptions = [
    {
      label: "Pricing",
      link: "/pricing",
      props: {
        color: theme.palette.primary.contrastText
      }
    },
    {
      label: "Team",
      link: "/team",
      props: {
        color: theme.palette.primary.contrastText
      }
    },
    {
      label: "Contact",
      link: "/contact",
      props: {
        color: theme.palette.primary.contrastText
      }
    },
    {
      label: "Sign In",
      link: "/sign-in",
      props: {
        color: theme.palette.secondary.main
      }
    },

  ]


  const list = drawerOptions.map((text, index) => (
    <Link to={text.link} style={{ textDecoration: 'none', ...text.props }}>
      <ListItem button key={text} classes={{ root: classes.itemListRoot }}>
        {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}

        <ListItemText disableTypography style={{ marginLeft: theme.spacing(2) }}
          primary={<Typography variant="h6" style={text.props}>{text.label}</Typography>} />

      </ListItem>
    </Link>
  ))

  return (
    <div>
      <Drawer anchor="left" open={state} onClose={toggleDrawer} PaperProps={{ style: { backgroundColor: "transparent", marginTop: 84 } }} classes={{ root: classes.rootDrawer }}>
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <List>
            {list}
          </List>
        </div>
      </Drawer>
    </div>
  );
}