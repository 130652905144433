import React, { useRef, useEffect } from "react";
import { Typography, Card, CardMedia, Button, Hidden } from "@material-ui/core";
import landing from "../../../../images/landing1.png";
import BasicGradient from "../../../../utils/BasicGradient";
import { makeStyles } from "@material-ui/styles";
import CompWrapperWidth from "../../../../utils/CompWrapperWidth";
import LongButtonSecondary from "../../../../utils/LongButtonSecondary";
import { ReactComponent as MathLogo } from "../../../../images/mainLogo.svg";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(theme => ({
  landingTypoMain: {
    color: theme.palette.primary.contrastText,
    width: "max-content",
    fontWeight: "bold",
    [theme.breakpoints.down(1650)]: {
      width: "unset"
    },
    [theme.breakpoints.down("xs")]: {
      width: "51%"
    }
  },
  landingDiv: {
    maxWidth: 1800,
    [theme.breakpoints.down(1800)]: {
      width: "100vw",
      maxWidth: "unset"
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: 700,
    maxHeight: 1000,
    position: "relative"
  },
  imageLanding: {
    height: "90%",
    width: "auto",
    maxHeight: 700,
    [theme.breakpoints.down("lg")]: {
      maxHeight: 500
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: 450
    }
  },
  landingTopText: {
    [theme.breakpoints.up("xl")]: {
      width: "60%",
      marginLeft: 40
    },

    [theme.breakpoints.down("md")]: {
      width: "90%"
    },
    color: "lightgray",
    marginTop: theme.spacing(4),
    fontWeight: 300,
    [theme.breakpoints.down("xs")]: {
      width: "unset"
    }
  },
  landingTopTextDiv: {
    maxWidth: 275,
    minWidth: 300,
    [theme.breakpoints.up("lg")]: {
      minWidth: 370
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 115,
      maxWidth: 300,
      minWidth: 200,
      position: "absolute",
      bottom: 20,
      zIndex: 999
    }
  },
  buttonLandingTopDiv: {
    [theme.breakpoints.up("xl")]: {
      marginLeft: 40
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      width: "calc(100vw - 32px)",
      marginRight: theme.spacing(4)
    }
  },
  landingTopImageDiv: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginLeft: 70,
    [theme.breakpoints.only("lg")]: {
      marginLeft: 150
    },
    [theme.breakpoints.up(1800)]: {
      transform: "translateX(200px)"
    }
    // [theme.breakpoints.down(1700)]: {
    //   transform: 'translateX(30px)'
    // },
    // [theme.breakpoints.down("xs")]: {
    //   transform: 'translateX(20px)'
    // },
  }
}));
const LandingTop = ({ setLandingTopHeight }) => {
  const classes = useStyles();
  const history = useHistory();
  const landingTopDiv = useRef(null);
  useEffect(() => {
    if (landingTopDiv) {
      setLandingTopHeight(landingTopDiv.current.scrollHeight);
    }
  }, [landingTopDiv]);
  // const landingTopHeight = landingTopDiv.scrollHeight
  // if (landingTopDiv.current) {
  //   console.log(landingTopDiv.current.getBoundingClientRect)
  // }
  return (
    <div ref={landingTopDiv}>
      <BasicGradient>
        <CompWrapperWidth>
          <div className={classes.landingDiv}>
            <div className={classes.landingTopTextDiv}>
              <Hidden smUp>
                <Typography variant="h3" className={classes.landingTypoMain}>
                  Hash + People = Hashple
                </Typography>
              </Hidden>
              <Hidden xsDown>
                <div style={{ maxWidth: 380 }}>
                  <MathLogo />
                </div>
              </Hidden>
              <Typography variant="h5" className={classes.landingTopText}>
                Safely reopen your business with Hashple occupancy management
                solution.
              </Typography>
              <div className={classes.buttonLandingTopDiv}>
                <LongButtonSecondary
                  label="Learn More"
                  onClick={() => history.push("/sign-up")}
                />
              </div>
            </div>
            <div className={classes.landingTopImageDiv}>
              <img src={landing} className={classes.imageLanding} />
            </div>
          </div>
        </CompWrapperWidth>
      </BasicGradient>
    </div>
  );
};

export default LandingTop;
