import React from 'react'
import { useTheme, Typography } from '@material-ui/core';
import CompWrapperWidth from '../CompWrapperWidth';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({
  basicWhiteCompSubtext: {
    color: theme.palette.primary.sub,
    width: '37%',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: '45%'
    },
    [theme.breakpoints.down("sm")]: {
      width: '60%'
    },
    [theme.breakpoints.down("xs")]: {
      width: '90%'
    },
  },

}));
const BasicWhiteComp = ({ children, title, subtitle, align }) => {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <div style={{ backgroundColor: theme.palette.primary.contrastText, height: '100%', display: 'flex', overflow: 'hidden', flexDirection: 'column', paddingTop: theme.spacing(6), paddingBottom: theme.spacing(6) }}>
      <CompWrapperWidth>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: `${align ? align : "none"}`, marginTop: theme.spacing(5), marginBottom: theme.spacing(5), alignItems: 'center' }}>
          {!!title && <Typography variant="h3" style={{ color: theme.palette.primary.darkGrey, fontWeight: 'bold' }}>
            {title}
          </Typography>}
          {!!subtitle && <Typography variant="h5" className={classes.basicWhiteCompSubtext} >
            {subtitle}
          </Typography>}
        </div>
        {children}
      </CompWrapperWidth>
    </div>
  )

}
export default BasicWhiteComp;