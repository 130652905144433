import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  useTheme,
  Hidden,
  ButtonBase
} from "@material-ui/core";
import { NavLink, Link } from "react-router-dom";
import { ReactComponent as SmallLogo } from "../../images/smallLogo.svg";
import CloseIcon from "@material-ui/icons/Close";
import SideDrawer from "./SideDrawer";
import { useHistory } from "react-router-dom"
import { getSession } from "../../services/CognitoService";
const useStyles = makeStyles(theme => ({
  headerMainDiv: {
    position: "relative",
    zIndex: 999999999,
    height: 60,
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "auto",
    paddingLeft: theme.spacing(14),
    paddingRight: theme.spacing(14),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(3)
    },
    maxWidth: 1800
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.sub,
    marginRight: theme.spacing(4)
  },
  linksDiv: {
    display: "flex"
  },
  activeClassName: {
    color: "white"
  },
  headerTitle: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(4),
    width: "fit-content"
  },
  typoLinkHeader: {
    fontWeight: 300
  },
  headerSmallLogo: {
    userSelect: "none",
    [theme.breakpoints.up("sm")]: {
      cursor: "pointer"
    },
  }
}));
const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory()
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  async function loadData() {
    const session = await getSession();
    if (session) {
      setUser(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);
  const toggleDrawer = event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    console.log("in toggle ");
    setDrawerOpen(false);
  };
  const handleBackToHome = () => {
    setDrawerOpen(false);
    history.push("/");
  }
  return (
    <React.Fragment>
      <div className={classes.headerMainDiv}>
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <Hidden xsDown>
            <Link
              className={classes.headerTitle}
              style={{ textDecoration: "none", height: "55%" }}
              exact
              to="/"
            >
              <div style={{ height: "100%" }}>
                <SmallLogo />
              </div>
            </Link>
          </Hidden>
          <Hidden xsDown>
            <div className={classes.linksDiv}>
              <Typography className={classes.typoLinkHeader}>
                <NavLink
                  className={classes.link}
                  activeStyle={{ color: theme.palette.primary.contrastText }}
                  to="/pricing"
                >
                  Pricing
                </NavLink>
              </Typography>
              {/*
              <Typography className={classes.typoLinkHeader}>
                {" "}
                <NavLink
                  className={classes.link}
                  activeStyle={{ color: theme.palette.primary.contrastText }}
                  to="/team"
                >
                  Team
                </NavLink>
              </Typography>
              */}
              <Typography className={classes.typoLinkHeader}>
                {" "}
                <NavLink
                  className={classes.link}
                  activeStyle={{ color: theme.palette.primary.contrastText }}
                  to="/contact"
                >
                  Contact
                </NavLink>
              </Typography>
            </div>
          </Hidden>
        </div>
        <Hidden xsDown>
          <div>
            <Typography className={classes.typoLinkHeader}>
              {" "}
              {!loading && (
                <React.Fragment>
                  {!user ? (
                    <NavLink
                      className={classes.link}
                      style={{ marginRight: 0 }}
                      activeStyle={{
                        color: theme.palette.primary.contrastText
                      }}
                      to="/sign-in"
                    >
                      Sign In
                    </NavLink>
                  ) : (
                    <Typography
                      style={{
                        color: theme.palette.primary.contrastText,
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        (window.location = "https://app.hashple.io")
                      }
                    >
                      Dashboard
                    </Typography>
                  )}
                </React.Fragment>
              )}
            </Typography>
          </div>
        </Hidden>
        <Hidden smUp>
          <div
            className={classes.headerTitle}
            style={{ height: "55%", marginRight: "auto", width: "100%" }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "space-between"

              }}
            >
              <SmallLogo
                onClick={() => drawerOpen ? handleBackToHome() : setDrawerOpen(true)}
                className={classes.headerSmallLogo}
              />
              {drawerOpen && (
                <ButtonBase onClick={toggleDrawer}>
                  <CloseIcon
                    fontSize="large"
                    style={{ color: theme.palette.primary.contrastText }}
                  />
                </ButtonBase>
              )}
            </div>
          </div>
        </Hidden>
      </div>
      <Hidden smUp>
        <SideDrawer state={drawerOpen} toggleDrawer={toggleDrawer} />
      </Hidden>
    </React.Fragment>
  );
};

export default Header;
