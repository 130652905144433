import React, { useState } from "react";
import TemplateGradientCard from "../../utils/TemplateGradientCard";
import { signUpUser, signInUser } from "../../services/CognitoService";
import { createCustomer } from "../../services/CustomerService";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import LongButtonSecondary from "../../utils/LongButtonSecondary";

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  function onChange({ target }) {
    const { name: thisName, value } = target;
    if (thisName === "email") {
      setEmail(value);
    } else if (thisName === "password") {
      setPassword(value);
    } else if (thisName === "name") {
      setName(value);
    } else if (thisName === "confirmPassword") {
      setConfirmPassword(value);
    }
  }

  async function handleSignUpClick() {
    if (!password || !confirmPassword || !name || !email) {
      alert("Error: You must fill out all of the fields.");
      return;
    }
    if (password !== confirmPassword) {
      alert("Error: Password and confirm password do not match!");
      return;
    }

    try {
      const resp = await signUpUser(email, password);
      const { userSub } = resp;
      await createCustomer({
        email: email,
        sub: userSub,
        name: name,
      });
      console.log("resp: ", resp);
      await signInUser({ email: email, password: password });
      setShowDialog(true);
    } catch (e) {
      alert("There was an error registering");
    }
  }

  return (
    <React.Fragment>
      {showDialog && (
        <Dialog open fullWidth>
          <DialogTitle style={{ textAlign: "center" }}>
            <Typography variant="h4">Welcome To Hashple!</Typography>
          </DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography style={{ textAlign: "center", maxWidth: "60%" }}>
              A member of our sales team will reach out within 1 business day to
              finish setting up your account!
            </Typography>
            <Typography
              style={{ textAlign: "center", marginTop: 20, maxWidth: "60%" }}
            >
              If you have any further questions, feel free to Contact Us!
            </Typography>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <LongButtonSecondary
              label={"Close"}
              style={{ marginTop: 20, height: "100%" }}
              onClick={() => setShowDialog(false)}
            />
          </DialogActions>
        </Dialog>
      )}
      <TemplateGradientCard
        data={[
          {
            type: "TITLE",
            title: "Create Account",
          },
          {
            type: "TEXTFIELD_REGULAR",
            placeholder: "Name",
            style: {
              marginTop: 40,
            },
            props: {
              onChange: onChange,
              name: "name",
              value: name,
            },
          },
          {
            type: "TEXTFIELD_REGULAR",
            placeholder: "Email",
            props: {
              onChange: onChange,
              name: "email",
              value: email,
            },
          },
          {
            type: "TEXTFIELD_REGULAR",
            placeholder: "Password",
            realType: "password",
            props: {
              type: "password",
              onChange: onChange,
              name: "password",
              value: password,
            },
          },
          {
            type: "TEXTFIELD_REGULAR",
            placeholder: "Confirm Password",
            realType: "password",

            props: {
              type: "password",
              onChange: onChange,
              name: "confirmPassword",
              value: confirmPassword,
            },
          },
          {
            type: "BUTTON",
            onClick: handleSignUpClick,
            label: "Sign Up",
            style: {
              minWidth: 55,
              width: "87%",
              maxWidth: 200,
              height: 40,
              paddingTop: 40,
            },
          },
          {
            type: "TEXT_AND_LINK",
            text: "Already have an account?",
            link: "/sign-in",
            linkText: "Sign In",
          },
        ]}
      />
    </React.Fragment>
  );
};

export default SignUp;
