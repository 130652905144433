import React from 'react'
import { useTheme } from '@material-ui/core';

const BasicWhite = ({ children }) => {
  const theme = useTheme()
  return (
    <div style={{ backgroundColor: theme.palette.primary.contrastText, height: '100%', display: 'flex', overflow: 'hidden', paddingTop: theme.spacing(6), paddingBottom: theme.spacing(6), justifyContent: 'center' }}>
      {children}
    </div>
  )

}
export default BasicWhite;