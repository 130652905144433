import React from "react"
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles(theme => ({
  landingTopButton: {
    color: theme.palette.primary.contrastText,
    textTransform: 'none',
    padding: '3px 50px',
    marginTop: theme.spacing(4),
    fontSize: '1rem',
    borderRadius: 11
  },

}));
const LongButtonSecondary = ({ onClick, label, style }) => {
  const classes = useStyles()
  return (
    <Button variant="contained" color="secondary" className={classes.landingTopButton} style={{ ...style }} onClick={onClick}>{label}</Button>
  )
}

export default LongButtonSecondary