import React from 'react'
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  widthWrapDiv: {
    maxWidth: 1800,
    paddingLeft: theme.spacing(14),
    paddingRight: theme.spacing(14),
    [theme.breakpoints.up("lg")]: {
      margin: '0px auto'
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    },
  }
}));
const CompWrapperWidth = ({ children, display }) => {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <div className={classes.widthWrapDiv} style={{display: display? display : "block"}} >
      {children}
    </div>
  )
}
export default CompWrapperWidth