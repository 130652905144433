import HttpService from "../HttpService";
import { apiEndpoint } from "../../config";

const customerUrl = `${apiEndpoint}/customer`;

export async function createCustomer({ email, sub, name, parent }) {
  const body = {
    email,
    sub,
    name,
  };

  if (parent) {
    body.parent = parent;
  }
  try {
    await HttpService.post(customerUrl, body);
  } catch (e) {
    throw e;
  }
}
