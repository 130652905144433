import React from "react";
import BasicWhiteComp from "../../../../utils/BasicWhiteComp";
import {
  Grid,
  Typography,
  makeStyles,
  useTheme,
  Hidden
} from "@material-ui/core";
import gridOne from "../../../../images/gridOne.png";
import gridTwo from "../../../../images/gridTwo.png";
import gridThree from "../../../../images/gridThree.png";
import CompWrapperWidth from "../../../../utils/CompWrapperWidth";

const useStyles = makeStyles(theme => ({
  landingSecondDiv: {
    maxWidth: 1400
  },
  gridItem: {
    padding: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
      margin: "auto"
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  gridItemText: {
    padding: theme.spacing(7),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4),
      margin: "auto"
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  text: {
    color: theme.palette.primary.darkGrey,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  divTextLandingSecond: {
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "90%"
    }
  },
  container: {
    justifyContent: "center"
  },
  gridItemContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    }
  },
  subtext: {
    color: "gray",
    fontWeight: "lighter"
  }
}));

const LandingSecond = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <BasicWhiteComp title="" subtitle="" align="center">
      {/* <CompWrapperWidth> */}
      <div className={classes.landingSecondDiv}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            justify="center"
            className={classes.gridItemContainer}
          >
            <Hidden mdUp>
              <Typography
                variant="h3"
                className={classes.text}
                style={{ fontSize: "2rem" }}
              >
                Customizable
              </Typography>
            </Hidden>
            <Grid item sm={12} md={6} className={classes.gridItem}>
              <img src={gridOne} style={{ maxWidth: "100%" }} />
            </Grid>
            <Hidden mdUp>
              <Typography
                variant="h5"
                className={`${classes.text} ${classes.subtext}`}
              >
                With Hashple JS scripting you can customize actions, why just
                update a display when you can interact with smart locks, send
                alerts or invoke remote APIs.
              </Typography>
            </Hidden>
            <Hidden smDown>
              <Grid item sm={12} md={6} className={classes.gridItemText}>
                <div className={classes.divTextLandingSecond}>
                  <Typography
                    variant="h3"
                    className={classes.text}
                    style={{ fontSize: "2.5rem" }}
                  >
                    Open
                  </Typography>
                  <Typography
                    variant="h5"
                    className={`${classes.text} ${classes.subtext}`}
                    style={{ marginTop: 10 }}
                  >
                    Hashple integrates with different vendors to create a
                    flexible and open solution that adapts your needs. Use
                    thermal counters, plate recognition systems, identity
                    document scanning, or smart cameras.
                  </Typography>
                </div>
              </Grid>
            </Hidden>
          </Grid>

          <Grid
            item
            container
            xs={12}
            justify="center"
            className={classes.gridItemContainer}
          >
            <Hidden smDown>
              <Grid item sm={12} md={6} className={classes.gridItemText}>
                <div className={classes.divTextLandingSecond}>
                  <Typography
                    variant="h3"
                    className={classes.text}
                    style={{ fontSize: "2.5rem" }}
                  >
                    Predictive
                  </Typography>
                  <Typography
                    variant="h5"
                    className={`${classes.text} ${classes.subtext}`}
                    style={{ marginTop: 10 }}
                  >
                    At Hashple we know how important is to be a step ahead in
                    your business, plan underutilization or saturations weeks
                    before they happen with Deep Learning and Advance Time
                    Series Analysis.
                  </Typography>
                </div>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Typography
                variant="h3"
                className={classes.text}
                style={{ fontSize: "2rem" }}
              >
                Customizable
              </Typography>
            </Hidden>
            <Grid item sm={12} md={6} className={classes.gridItem}>
              <img src={gridTwo} style={{ maxWidth: "100%" }} />
            </Grid>
            <Hidden mdUp>
              <Typography
                variant="h5"
                className={`${classes.text} ${classes.subtext}`}
              >
                With Hashple JS scripting you can customize actions, why just
                updating a display when you can interact with smart locks, send
                alerts or invoke remote APIs.
              </Typography>
            </Hidden>
          </Grid>

          <Grid
            item
            container
            xs={12}
            justify="center"
            className={classes.gridItemContainer}
          >
            <Hidden mdUp>
              <Typography
                variant="h3"
                className={classes.text}
                style={{ fontSize: "2rem" }}
              >
                Predictive
              </Typography>
            </Hidden>
            <Grid item sm={12} md={6} className={classes.gridItem}>
              <img src={gridThree} style={{ maxWidth: "100%" }} />
            </Grid>
            <Hidden mdUp>
              <Typography
                variant="h5"
                className={`${classes.text} ${classes.subtext}`}
              >
                At Hashple we know how important is to be a step ahead in your
                business, plan underutilization or saturations weeks before they
                happen with Deep Learning and Advance Time Series Analysis.
              </Typography>
            </Hidden>
            <Hidden smDown>
              <Grid item sm={12} md={6} className={classes.gridItemText}>
                <div className={classes.divTextLandingSecond}>
                  <Typography
                    variant="h3"
                    className={classes.text}
                    style={{ fontSize: "2.5rem" }}
                  >
                    Customizable
                  </Typography>
                  <Typography
                    variant="h5"
                    className={`${classes.text} ${classes.subtext}`}
                    style={{ marginTop: 10 }}
                  >
                    With Hashple JS scripting you can customize actions, why
                    just update a display when you can interact with smart
                    locks, send alerts or invoke remote APIs.
                  </Typography>
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </div>
      {/* </CompWrapperWidth> */}
    </BasicWhiteComp>
  );
};
export default LandingSecond;
