import React from "react";
import { Typography, Paper, useTheme, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import LongButtonSecondary from "../LongButtonSecondary";

const useStyles = makeStyles((theme) => ({
  rootReg: {
    "&:not(.Mui-disabled):hover::before": {
      borderColor: theme.palette.secondary.dark,
    },
  },
  root: {
    "&:hover $notchedOutline": {
      borderColor: theme.palette.secondary.main,
    },
    borderRadius: 0,
  },
  disabled: {},
  notchedOutline: {
    borderColor: "#ffffff00",
  },
  basicWhiteCompSubtext: {
    marginTop: 10,
    fontWeight: 300,
    textAlign: "center",
    color: theme.palette.primary.sub,
  },
  paperTextfields: {
    display: "flex",
    flexDirection: "column",
    padding: "40px 75px",
    [theme.breakpoints.down("md")]: {
      padding: "40px 40px",
    },
    alignItems: "center",
    borderRadius: 17,
  },
}));
const PaperTextfields = ({ data, children }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Paper className={classes.paperTextfields}>
      {data.map((item) => {
        if (item.type === "TEXTFIELD_REGULAR") {
          return (
            <TextField
              placeholder={item.placeholder}
              style={{ width: "100%", marginTop: 30, ...item.style }}
              color="secondary"
              {...item.props}
              type={item.realType}
              InputProps={{
                classes: {
                  root: classes.rootReg,
                },
              }}
            />
          );
        } else if (item.type === "TEXTFIELD_MULTILINE") {
          return (
            <TextField
              variant="outlined"
              color="secondary"
              placeholder={item.placeholder}
              {...item.props}
              rows={5}
              multiline
              style={{
                width: "100%",
                height: "100%",
                marginTop: 20,
                ...item.style,
              }}
              InputProps={{
                classes: {
                  root: classes.root,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          );
        } else if (item.type === "BUTTON") {
          return (
            <div style={item.style}>
              <LongButtonSecondary
                label={item.label}
                style={{ width: "100%", marginTop: 0, height: "100%" }}
                onClick={item.onClick}
              />
            </div>
          );
        } else if (item.type === "TITLE") {
          return (
            <Typography
              variant="h4"
              style={{
                color: theme.palette.primary.darkGrey,
                marginTop: 50,
                textAlign: "center",
                ...item.style,
              }}
            >
              {item.title}
            </Typography>
          );
        } else if (item.type === "SUBTITLE") {
          return (
            <Typography
              variant="subtitle1"
              className={classes.basicWhiteCompSubtext}
              style={{ ...item.style }}
            >
              {item.subtitle}
            </Typography>
          );
        } else if (item.type === "SIDE_LINK") {
          return (
            <Typography
              variant="caption"
              style={{
                marginTop: 30,
                color: theme.palette.primary.darkGrey,
                cursor: "pointer",
                ...item.style,
              }}
              onClick={item.onClick}
            >
              {item.text}
            </Typography>
          );
        } else if (item.type === "TEXT_AND_LINK") {
          return (
            <Typography
              variant="caption"
              style={{
                marginTop: 30,
                color: theme.palette.primary.darkGrey,
                ...item.style,
              }}
            >
              {item.text}{" "}
              <Link
                to={item.link}
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.darkGrey,
                }}
              >
                {item.linkText}
              </Link>
            </Typography>
          );
        } else {
          return null;
        }
      })}
      {children}
    </Paper>
  );
};

export default PaperTextfields;
