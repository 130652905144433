import React from "react";
import { useTheme, Typography } from "@material-ui/core";
import CompWrapperWidth from "../CompWrapperWidth";
import { makeStyles } from "@material-ui/styles";
import BasicGradient from "../BasicGradient";
const useStyles = makeStyles(theme => ({
  basicGradientCompSubtext: {
    color: theme.palette.primary.sub,
    width: "37%",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: "45%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "60%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%"
    }
  },
  basicGradientDivComp: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    alignItems: "center"
  }
}));
const BasicGradientComp = ({ children, title, subtitle }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <BasicGradient style={{ justifyContent: "center" }}>
      <CompWrapperWidth display="flex">
        <div style={{ marginTop: "auto", marginBottom: "auto" }}>
          <div className={classes.basicGradientDivComp}>
            {!!title && (
              <Typography
                variant="h3"
                style={{
                  color: theme.palette.primary.contrastText,
                  fontWeight: "bold"
                }}
              >
                {title}
              </Typography>
            )}
            {!!subtitle && (
              <Typography
                variant="h5"
                className={classes.basicGradientCompSubtext}
              >
                {subtitle}
              </Typography>
            )}
          </div>
          {children}
        </div>
      </CompWrapperWidth>
    </BasicGradient>
  );
};
export default BasicGradientComp;
