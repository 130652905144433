import React, { useState } from "react";
import TemplateGradientCard from "../../utils/TemplateGradientCard";
import RecoverPassword from "../RecoverPassword";
import { signInUser } from "../../services/CognitoService";

const SignIn = () => {
  const [forgotPass, setForgotPass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function handleSignInClick() {
    if (!email || !password) {
      alert("Error: You must fill out all of the fields");
      return;
    }
    try {
      await signInUser({ email, password });
      window.location = "https://app.hashple.io";
    } catch (e) {
      console.log("Error signing up", e);
      alert(e);
    }
  }

  function onChange({ target }) {
    const { name, value } = target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  }

  return (
    <React.Fragment>
      {forgotPass ? (
        <RecoverPassword />
      ) : (
        <TemplateGradientCard
          data={[
            {
              type: "TITLE",
              title: "Welcome",
            },
            {
              type: "TEXTFIELD_REGULAR",
              placeholder: "Email",
              style: {
                marginTop: 40,
              },
              props: {
                value: email,
                onChange: onChange,
                name: "email",
              },
            },
            {
              type: "TEXTFIELD_REGULAR",
              placeholder: "Password",
              realType: "password",
              props: {
                value: password,
                onChange: onChange,
                name: "password",
              },
            },
            // {
            //   type: "SIDE_LINK",
            //   text: "Forgot password?",
            //   style: {
            //     fontStyle: "italic",
            //     alignSelf: "flex-end",
            //   },
            //   onClick: () => setForgotPass(true),
            // },
            {
              type: "BUTTON",
              onClick: handleSignInClick,
              label: "Sign In",
              style: {
                minWidth: 55,
                width: "87%",
                maxWidth: 200,
                height: 40,
                paddingTop: 20,
              },
            },
            {
              type: "TEXT_AND_LINK",
              text: "Don't have an account?",
              link: "/sign-up",
              linkText: "Sign Up",
            },
          ]}
        />
      )}
    </React.Fragment>
  );
};

export default SignIn;
