import React, { useState, useEffect } from "react";
import LandingTop from "./components/LandingTop";
import LandingSecond from "./components/LandingSecond";
import LandingThird from "./components/LandingThird";
import LandingBottom from "./components/LandingBottom";
import { useScroll } from "../../utils/Hooks";
import LongButtonSecondary from "../../utils/LongButtonSecondary";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  fabDiv: {
    width: "80%",
    [theme.breakpoints.up("sm")]: {
      marginRight: 20,
      maxWidth: 300
    }
  },
  divDivButton: {
    position: "fixed",
    bottom: 50,
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      right: 0,
      width: "45%",
      justifyContent: "flex-end",
    }
  }
}));

const Home = () => {
  const [landingTopHeight, setLandingTopHeight] = useState(0);
  const [show, setShow] = useState(false);
  const scroll = useScroll();
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    if (landingTopHeight) {
      setShow(scroll.y > landingTopHeight);
    }
  }, [scroll, landingTopHeight]);

  return (
    <div style={{ position: "relative" }}>
      <LandingTop setLandingTopHeight={setLandingTopHeight} />
      <LandingSecond />
      <LandingThird />
      <LandingBottom />
      {show && (
        <div
          className={classes.divDivButton}
          style={{

          }}
        >
          {" "}
          <div className={classes.fabDiv}>
            <LongButtonSecondary
              onClick={() => history.push("/sign-up")}
              style={{ width: "100%" }}
              label="Get Started"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
