import React from 'react'
import { Card, Typography, Grid, makeStyles, useTheme } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  teamCardDescription: {
    width: '60%',
    textAlign: 'center',
    color: theme.palette.primary.sub,
    marginBottom: 30
  }
}));
const TeamCard = ({ img, title, name, description }) => {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <Grid xs={12} sm={6} lg={4} item >
      <Card style={{boxShadow: " 0px 20px 38px #00000014", height: '100%', borderRadius: 17 }}>
        <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
          <Typography variant="h5" style={{ color: theme.palette.secondary.main, fontWeight: 'bold', marginTop: 30 }}>{name}</Typography>
          <div style={{ display: "flex", justifyContent: 'center' }}>
            <img src={img} style={{ width: '90%' }} />
          </div>
          <Typography variant="h5" style={{ color: theme.palette.primary.darkGrey, fontWeight: 'bold', textAlign: 'center', width: '60%', }}>{title}</Typography>
          <Typography className={classes.teamCardDescription}>{description}</Typography>
        </div>
      </Card>
    </Grid >
  )

}
export default TeamCard;