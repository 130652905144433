import React from "react";
import { Typography, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PaperTextfields from "../PaperTextfields";
import BasicGradient from "../BasicGradient";
import CompWrapperWidth from "../CompWrapperWidth";
const useStyles = makeStyles(theme => ({
  landingTypoMain: {
    color: theme.palette.primary.contrastText,
    width: "fit-content",
    fontWeight: "bold"
  },
  templateGradientCardDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    maxWidth: 1800,
    paddingLeft: theme.spacing(14),
    paddingRight: theme.spacing(14),
    paddingBottom: theme.spacing(14),
    paddingTop: theme.spacing(14),
    [theme.breakpoints.up("lg")]: {
      margin: "0px auto"
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      flexDirection: "column",
      paddingTop: theme.spacing(0),
      justifyContent: "flex-start"
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  landingTopText: {
    [theme.breakpoints.up("md")]: {
      width: "60%"
    },

    [theme.breakpoints.down("md")]: {
      width: "90%"
    },
    color: theme.palette.primary.sub,
    marginTop: theme.spacing(4),
    fontWeight: 300,
    [theme.breakpoints.down("xs")]: {
      width: "unset",
      marginTop: theme.spacing(1)
    }
  },
  paperTextfieldsDiv: {
    margin: "0px auto",
    [theme.breakpoints.up("lg")]: {
      width: "25%"
    },
    [theme.breakpoints.down("lg")]: {
      width: "35%"
    },

    [theme.breakpoints.down("md")]: {
      width: "50%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%"
    }
  },
  landingTopTextDiv: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "69%",
      marginRight: 30
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      alignSelf: "flex-start",
      paddingBottom: 20
    }
  }
}));
const TemplateGradientCard = ({ data }) => {
  const classes = useStyles();

  return (
    <BasicGradient>
      <div className={classes.templateGradientCardDiv}>
        <Hidden smDown>
          <div className={classes.landingTopTextDiv}>
            <Typography variant="h3" className={classes.landingTypoMain}>
              Hash + People = Hashple
          </Typography>
            <Typography variant="h5" className={classes.landingTopText}>
              Safely reopen your business with Hashple occupancy management
              solution.
          </Typography>
          </div>
        </Hidden>
        <div className={classes.paperTextfieldsDiv}>
          <PaperTextfields data={data} />
        </div>
      </div>
    </BasicGradient>
  );
};

export default TemplateGradientCard;
