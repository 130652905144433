import React from "react";
import NarrowWidthWrap from "../../utils/NarrowWidthWrap";
import BasicWhiteComp from "../../utils/BasicWhiteComp";
import {
  Card,
  Typography,
  CardHeader,
  Paper,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LongButtonSecondary from "../../utils/LongButtonSecondary";
const useStyles = makeStyles(theme => ({
  contactInfoTitle: {
    color: theme.palette.primary.darkGrey
  },
  contactInfoSub: {
    color: theme.palette.primary.darkGrey,
    width: "70%"
  },
  contactInfoBlueText: {
    color: theme.palette.secondary.main
  },
  contactWrapDiv: {
    display: "flex"
  },
  contactPaper: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    boxShadow: "0px 20px 38px #00000014",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  textfieldNotchedOutlined: {
    borderColor: "red"
  },
  rootReg: {
    "&:not(.Mui-disabled):hover::before": {
      borderColor: theme.palette.secondary.dark
    }
  },
  root: {
    "&:hover $notchedOutline": {
      borderColor: theme.palette.secondary.main
    },
    borderRadius: 0
  },
  disabled: {},
  notchedOutline: {
    borderColor: "#ffffff00"
  },
  textfieldsDiv: {
    display: "flex",
    marginTop: 20,
    [theme.breakpoints.up("lg")]: {
      minWidth: 500
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100%"
    }
  },
  mainContactDiv: {
    display: "flex",
    marginBottom: 40,
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center"
    },
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap"
    }
  },
  contactInfoDivs: {
    marginTop: theme.spacing(2)
  },
  paperDiv: {
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  textDiv: {
    width: "max-content",
    paddingBottom: 30,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 30
    }
  },
  nameTextfield: {
    flex: 1,
    [theme.breakpoints.up("sm")]: {
      marginRight: 30
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 20
    }
  }
}));
const Contact = () => {
  const classes = useStyles();
  return (
    <BasicWhiteComp title="Contact Us" align="center">
      <div>
        {/* <NarrowWidthWrap display="flex"> */}
        <div className={classes.mainContactDiv}>
          <div className={classes.textDiv} style={{}}>
            <div className={classes.contactInfoDivs}>
              <Typography className={classes.contactInfoBlueText}>
                Contact Info
              </Typography>
            </div>
            {/*
            <div className={classes.contactInfoDivs}>
              <Typography variant="h5" className={classes.contactInfoTitle}>
                Customer Service
              </Typography>
              <Typography className={classes.contactInfoSub}>
                +1 (292) 999-9999
              </Typography>
            </div>
            */}
            <div className={classes.contactInfoDivs}>
              <Typography variant="h5" className={classes.contactInfoTitle}>
                Address
              </Typography>
              <Typography className={classes.contactInfoSub}>
                6810 FL-7 Coconut Creek, FL 33073
              </Typography>
            </div>
            <div className={classes.contactInfoDivs}>
              <Typography variant="h5" className={classes.contactInfoTitle}>
                Email
              </Typography>
              <Typography className={classes.contactInfoSub}>
                info@hashple.com
              </Typography>
            </div>
          </div>
          <div className={classes.paperDiv}>
            <Paper className={classes.contactPaper}>
              <Typography variant="h5" className={classes.contactInfoTitle}>
                How can we help?
              </Typography>
              <div className={classes.textfieldsDiv} style={{}}>
                <TextField
                  placeholder="Name"
                  color="secondary"
                  className={classes.nameTextfield}
                  style={{ flex: 1 }}
                  InputProps={{
                    classes: {
                      root: classes.rootReg
                    }
                  }}
                />
                <TextField
                  placeholder="Email"
                  color="secondary"
                  style={{ flex: 1 }}
                  InputProps={{
                    classes: {
                      root: classes.rootReg
                    }
                  }}
                />
              </div>
              <TextField
                placeholder="Subject"
                style={{ width: "100%", marginTop: 20 }}
                color="secondary"
                InputProps={{
                  classes: {
                    root: classes.rootReg
                  }
                }}
              />
              <TextField
                variant="outlined"
                color="secondary"
                placeholder="Compose email here..."
                rows={5}
                multiline
                style={{ width: "100%", height: "100%", marginTop: 20 }}
                InputProps={{
                  classes: {
                    root: classes.root,
                    notchedOutline: classes.notchedOutline
                  }
                }}
              />
              <div style={{ width: "fit-content" }}>
                <LongButtonSecondary label="Send" onClick={() => {}} />
              </div>
            </Paper>
          </div>
        </div>
        {/* </NarrowWidthWrap> */}
      </div>
    </BasicWhiteComp>
  );
};

export default Contact;
