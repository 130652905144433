import React, { useState, useCallback } from "react";
import { useTransition, animated } from "react-spring";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import BasicGradientComp from "../../../../utils/BasicGradientComp";
import actions from "../../../../images/hashple_actions.gif";
import prediction from "../../../../images/prediction.gif";
import { makeStyles } from "@material-ui/styles";
import { ButtonBase, useTheme } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  landingThreeDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  langingThirdText: {
    color: theme.palette.primary.sub
  },
  landingThreeImg: {
    maxWidth: "100%"
  },
  loadingBottomTransDiv: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    minHeight: 550,
    [theme.breakpoints.down(585)]: {
      minHeight: 500,

    },
    [theme.breakpoints.down(500)]: {
      minHeight: 450,

    },
  },
  landingBottomWrapDiv: {
    position: "relative"
  },
  bottomLangingWrapWidth: {
    width: "100%",
    maxWidth: 2000,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.only("md")]: {
      paddingLeft: theme.spacing(26),
      paddingRight: theme.spacing(26)
    },
    [theme.breakpoints.only("sm")]: {
      paddingLeft: theme.spacing(13),
      paddingRight: theme.spacing(13)
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0px auto"
    }
  },
}));

const LandingThird = () => {
  const classes = useStyles();
  const theme = useTheme()
  const [index, setIndex] = useState(0);
  const [inOut, setInOut] = useState(0);
  const onClickF = useCallback(() => {
    setInOut(0);
    setIndex(state => (state + 1) % 2);
  }, []);
  const onClickB = useCallback(() => {
    setInOut(1);
    setIndex(state => (state === 0 ? 1 : state - 1));
  }, []);
  const gifs = [
    ({ style }) => (
      <animated.div
        style={{
          ...style,
          willChange: "transform, opacity",

        }}
      >
        <img src={actions} className={classes.landingThreeImg} /></animated.div>),
    ({ style }) => (<animated.div
      style={{
        ...style,
        willChange: "transform, opacity",

      }}
    >
      <img src={prediction} className={classes.landingThreeImg} /></animated.div>)
  ]
  const transitions = useTransition(index, p => p, {
    from: {
      opacity: 0,
      transform:
        inOut === 0 ? "translate3d(100%,0,0)" : "translate3d(-100%,0,0)",
      position: "absolute"
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0%,0,0)",
      position: "relative"
    },
    leave: {
      opacity: 0,
      transform: inOut === 0 ? "translate3d(-50%,0,0)" : "translate3d(50%,0,0)",
      position: "absolute"
    }
  });
  return (
    <BasicGradientComp title={index === 0? "Customizable" : "Predictive"} subtitle="">
      <div className={classes.landingThreeDiv}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative"
          }}
        >
          <div className={classes.loadingBottomTransDiv}>
            {transitions.map(({ item, props, key }) => {
              const Page = gifs[item];
              return <Page key={key} style={props} />;
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20
            }}
          >
            <div style={{ width: "fit-content" }}>
              <ButtonBase onClick={onClickB} style={{ marginRigth: 30 }}>
                <ArrowBackIosRoundedIcon
                  style={{ color: theme.palette.secondary.main }}
                />
              </ButtonBase>
              <ButtonBase onClick={onClickF} style={{ marginLeft: 30 }}>
                <ArrowForwardIosRoundedIcon
                  style={{ color: theme.palette.secondary.main }}
                />
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
    </BasicGradientComp>
  );
};

export default LandingThird;
