import React from "react";
import { Typography, makeStyles, Grid, Card } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  landingBottomImgDiv: {
    //  marginTop: 'auto',
    borderRadius: 15,
    width: "100%",
    maxHeight: 425,
    [theme.breakpoints.up("lg")]: {
      minHeight: 450
    },
    [theme.breakpoints.up("md")]: {
      minHeight: 400
    },
    [theme.breakpoints.down("md")]: {
      minHeight: 560
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    },
    [theme.breakpoints.down("xs")]: {
      flex: 1,
      minHeight: "unset",
      maxHeight: 325
      //  width: '90%'
    },
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  landingBottomTitle: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.primary.darkGrey
  },
  landingBottomSubtitle: {
    color: "gray",
    fontWeight: "lighter",
    marginBottom: theme.spacing(5),
    textAlign: "center",
    width: "90%",
    [theme.breakpoints.down("md")]: {
      width: "70%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "unset",
      maxHeight: 325
    }
  },
  landingBottomGridItem: {
    minHeight: 450,
    overflow: "hidden",
    [theme.breakpoints.up("lg")]: {
      padding: "60px 30px"
    },
    [theme.breakpoints.down("md")]: {
      height: "100%"
    }
  },
  landingBottomCard: {
    boxShadow: "0px 20px 38px #00000014",
    borderRadius: 17,
    height: "100%"
  },
  landingBottomInnerGridDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    height: "100%"
  },
  landingBottomImageDiv: {
    [theme.breakpoints.up("lg")]: {
      padding: 40
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    [theme.breakpoints.down("sm")]: {
      padding: 20
    }
  },
  landingBottomImage: {
    maxHeight: "100%",
    maxWidth: "100%",
    width: "auto",
    [theme.breakpoints.down("md")]: {}
  }
}));

const LandingBottomTemplate = ({ title, subtitle, img }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} lg={4} className={classes.landingBottomGridItem}>
      <Card className={classes.landingBottomCard}>
        <div className={classes.landingBottomInnerGridDiv}>
          {/* <div className={classes.landingBottomImgDiv} style={{ backgroundImage: `url(${img})`, backgroundPositionY: `${imgPos}`, backgroundSize: "cover" }} /> */}
          <div className={classes.landingBottomImageDiv}>
            <img src={img} className={classes.landingBottomImage} style={{}} />
          </div>
          <Typography variant="h3" className={classes.landingBottomTitle}>
            {title}
          </Typography>
          <Typography variant="h5" className={classes.landingBottomSubtitle}>
            {subtitle}
          </Typography>
        </div>
      </Card>
    </Grid>
  );
};

export default LandingBottomTemplate;
