import React from "react"
import TemplateGradientCard from "../../utils/TemplateGradientCard";

const RecoverPassword = () => {


  return (
    <TemplateGradientCard data={
      [
        {
          type: "TITLE",
          title: "Password Recovery",
        },
        {
          type: "SUBTITLE",
          subtitle: "Verify the email address associated with your account",
        },
        {
          type: "TEXTFIELD_REGULAR",
          placeholder: "Recovery Email",
        },
        {
          type: "BUTTON",
          onClick: () => { },
          label: "Send Email",
          style: {
            minWidth: 55,
            width: '87%',
            maxWidth: 290,
            height: 40,
            paddingTop: 40
          }
        }
      ]
    } />
  )
}

export default RecoverPassword