import React from "react";
import BasicHalfGradient from "../../utils/BasicHalfGradient";
import { Typography, Grid, makeStyles, useTheme } from "@material-ui/core";
import TeamCard from "../../utils/BasicHalfGradient/components/TeamCard";
import teamOne from "../../images/team1.png";
import teamTwo from "../../images/team2.png";
import teamThree from "../../images/team3.png";
import teamFour from "../../images/team4.png";
import teamFive from "../../images/team5.png";
import teamSix from "../../images/team6.png";
const useStyles = makeStyles(theme => ({
  teamDiv: {
    [theme.breakpoints.up("xl")]: {
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12)
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6)
    },

    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  basicGradientCompSubtext: {
    color: theme.palette.primary.sub,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: "60%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "60%",
      textAlign: "center"
    }
  },
  teamTitleDiv: {
    paddingBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center"
    }
  }
}));

const Team = () => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <BasicHalfGradient>
      <div className={classes.teamDiv}>
        <div className={classes.teamTitleDiv}>
          <Typography
            variant="h3"
            style={{
              color: theme.palette.primary.contrastText,
              fontWeight: "bold",
              width: "fit-content"
            }}
          >
            Meet the Hashple team
          </Typography>
        </div>
        <Grid container spacing={6}>
          <TeamCard
            img={teamOne}
            title="CEO"
            name="Abraham Nevado"
            description="At vero eos et accusamus et iusto odio dignissi fjd dhshs mos ducimus qui blanditiis"
          />
          <TeamCard
            img={teamTwo}
            title="CTO"
            name="Adrian Casanova"
            description="At vero eos et accusamus et iusto odio dignissi fjd dhshs mos ducimus qui blanditiis"
          />
        </Grid>
      </div>
    </BasicHalfGradient>
  );
};

export default Team;
