import React from 'react'
import { useTheme, Typography } from '@material-ui/core';
import CompWrapperWidth from '../CompWrapperWidth';
import { makeStyles } from '@material-ui/styles';
import BasicGradient from '../BasicGradient';
import NarrowWidthWrap from '../NarrowWidthWrap';
const useStyles = makeStyles(theme => ({
  basicGradientCompSubtext: {
    color: theme.palette.primary.sub,
    width: '37%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: '45%'
    },
    [theme.breakpoints.down("sm")]: {
      width: '60%'
    },
    [theme.breakpoints.down("xs")]: {
      width: '90%'
    },
  },
  basicGradientDivComp: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: "center",
    alignItems: "center",
  },
  halfGradientDiv: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    background: "rgb(245,245,245)",



    background: "linear-gradient(0deg, rgba(245,245,245,1) 66%, rgba(30,75,78,1) 66%, rgba(22,51,54,1) 76%, rgba(20,46,48,1) 80%, rgba(25,42,43,1) 83%, rgba(34,34,34,1) 91%, rgba(34,34,34,1) 100%)",
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down("md")]: {
      background: "linear-gradient(0deg, rgba(245,245,245,1) 75%, rgba(30,75,78,1) 66%, rgba(22,51,54,1) 76%, rgba(20,46,48,1) 80%, rgba(25,42,43,1) 83%, rgba(34,34,34,1) 91%, rgba(34,34,34,1) 100%)",

    },
    [theme.breakpoints.down("xs")]: {
      background: "linear-gradient(0deg, rgba(245,245,245,1) 85%, rgba(30,75,78,1) 85%, rgba(22,51,54,1) 94%, rgba(20,46,48,1) 96%, rgba(25,42,43,1) 97%, rgba(34,34,34,1) 99%, rgba(34,34,34,1) 100%)",
      minHeight: "100vh",
    },
  }

}));
const BasicHalfGradient = ({ children, title, subtitle, }) => {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <div className={classes.halfGradientDiv}>
      <NarrowWidthWrap>
        <div style={{ marginBottom: 'auto' }}>
          <div className={classes.basicGradientDivComp} >
            {!!title && <Typography variant="h3" style={{ color: theme.palette.primary.contrastText, fontWeight: 'bold' }}>
              {title}
            </Typography>}
            {!!subtitle && <Typography variant="h5" className={classes.basicGradientCompSubtext} >
              {subtitle}
            </Typography>}
          </div>
          {children}
        </div>
      </NarrowWidthWrap>
    </div >
  )

}
export default BasicHalfGradient;