import React, { useState } from "react";
import BasicHalfGradient from "../../utils/BasicHalfGradient";
import {
  Typography,
  Grid,
  makeStyles,
  useTheme,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  ButtonGroup,
  Button,
  Table
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import LongButtonSecondary from "../../utils/LongButtonSecondary";
import { height } from "dom-helpers";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(theme => ({
  pricingDiv: {
    [theme.breakpoints.up("xl")]: {
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12)
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6)
    },

    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  basicGradientCompSubtext: {
    color: "gray",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: "90%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "75%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      textAlign: "center"
    }
  },
  pricingTitleDiv: {
    paddingBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center"
    }
  },
  pricingPaper: {
    boxShadow: "0px 20px 38px #00000014",
    padding: theme.spacing(8),
    marginBottom: 100,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4)
    },
    borderRadius: 17
  },
  planSelectorDiv: {
    borderRadius: 11,
    border: `2px solid ${theme.palette.secondary.main}`,
    display: "flex"
  },
  root: {
    borderRadius: 10
  },
  longButtonDivPricing: {
    width: "50%",
    margin: "0px auto",
    maxWidth: 260,
    fontSize: "1.6rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem"
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem"
    }
  },
  priceDiv: {
    width: "85%",
    [theme.breakpoints.down("lg")]: {
      width: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%"
    }
  },
  pricingTitle: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  pricingPaperTitleGrid: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      marginBottom: 20
    }
  },
  pricingTitleTop: {
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
    width: "fit-content",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  }
}));
const pricingInfo = [
  {
    feature: "AI Predictive Analysis",
    value: <CheckIcon color="secondary" />
  },
  {
    feature: "Amount of Devices",
    value: <Typography color="secondary">Unlimited</Typography>
  },
  {
    feature: "Number of Actions",
    value: <Typography color="secondary">Unlimited</Typography>
  },
  {
    feature: "Total Customizable Metrics",
    value: <Typography color="secondary">Unlimited</Typography>
  },
  {
    feature: "Amount of Counters",
    value: <Typography color="secondary">2 Per Device</Typography>
  },
  {
    feature: "5 x 8 EST Customer Support",
    value: <CheckIcon color="secondary" />
  }
];

const Pricing = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [selectedPlan, setSelectedPlan] = useState(0);
  const history = useHistory();
  const planSelectedStyle = {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    flex: 1,
    borderWidth: 3,
    borderColor: theme.palette.secondary.main,
    textTransform: "none",
    fontSize: "1rem"
  };
  const planNotSelectedStyle = {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.secondary.main,
    flex: 1,
    borderWidth: 3,
    borderColor: theme.palette.secondary.main,
    textTransform: "none",
    fontSize: "1rem"
  };
  const monthlyStyle =
    selectedPlan === 0 ? planSelectedStyle : planNotSelectedStyle;
  const annualStyle =
    selectedPlan === 1 ? planSelectedStyle : planNotSelectedStyle;

  return (
    <BasicHalfGradient>
      <div className={classes.pricingDiv}>
        <div className={classes.pricingTitleDiv}>
          <Typography
            variant="h3"
            className={classes.pricingTitleTop}
            style={{}}
          >
            Plans & Pricing
          </Typography>
        </div>
        <Paper className={classes.pricingPaper}>
          <Grid container>
            <Grid item xs={12} container>
              <Grid xs={12} md={7} className={classes.pricingPaperTitleGrid}>
                <Typography
                  variant="h3"
                  className={classes.pricingTitle}
                  style={{
                    color: theme.palette.primary.darkGrey,
                    fontWeight: "bold",
                    width: "fit-content"
                  }}
                >
                  Simple transparent pricing
                </Typography>
                <Typography
                  variant="h5"
                  className={`${classes.basicGradientCompSubtext} ${classes.pricingTitle}`}
                >
                  Choose the best plan for you
                </Typography>
              </Grid>
              <Grid xs={12} md={5}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <div
                    className={classes.priceDiv}
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginBottom: 20
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginRight: 20
                      }}
                    >
                      <Typography
                        variant="h3"
                        style={{ color: theme.palette.secondary.main }}
                      >
                        $999
                      </Typography>
                      <Typography
                        style={{ color: theme.palette.primary.darkGrey }}
                      >
                        setup fee
                      </Typography>
                    </div>
                    <Typography
                      variant="h4"
                      style={{
                        color: theme.palette.primary.darkGrey,
                        fontWeight: 300,
                        marginTop: 5
                      }}
                    >
                      |
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginLeft: 20
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "flex-end" }}>
                        {" "}
                        <Typography
                          variant="h3"
                          style={{ color: theme.palette.secondary.main }}
                        >
                          {selectedPlan === 0 ? "$50" : "$290"}
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{ color: theme.palette.primary.darkGrey }}
                        >
                          /Device
                        </Typography>
                      </div>
                      <Typography
                        style={{
                          width: "fit-content",
                          alignSelf: "flex-end",
                          color: theme.palette.primary.darkGrey
                        }}
                      >
                        per {selectedPlan === 0 ? "month" : "year"}
                      </Typography>
                    </div>
                  </div>
                  {/* <div className={classes.planSelectorDiv}>
                    <div style={monthlyStyle}>Monthly Plan</div>
                    <div style={annualStyle}>Annual Plan</div>
                  </div> */}
                  <ButtonGroup
                    color="secondary"
                    aria-label="outlined primary button group"
                    className={classes.priceDiv}
                  >
                    <Button
                      style={monthlyStyle}
                      classes={{ root: classes.root }}
                      onClick={() => setSelectedPlan(0)}
                    >
                      Monthly Plan
                    </Button>
                    <Button
                      style={annualStyle}
                      classes={{ root: classes.root }}
                      onClick={() => setSelectedPlan(1)}
                    >
                      Annual Plan
                    </Button>
                  </ButtonGroup>
                </div>
              </Grid>
            </Grid>
            <Grid xs={12} style={{ marginTop: 40 }}>
              <Typography
                variant="h6"
                style={{ color: theme.palette.secondary.main, padding: 16 }}
              >
                Features
              </Typography>
              <Table>
                <TableBody>
                  {pricingInfo.map(row => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.feature}
                      </TableCell>
                      <TableCell align="right">{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <div className={classes.longButtonDivPricing} style={{}}>
            <LongButtonSecondary
              label="Get Started"
              onClick={() => history.push("/sign-up")}
              style={{ height: 55, width: "100%", fontSize: "inherit" }}
            />
          </div>
        </Paper>
      </div>
    </BasicHalfGradient>
  );
};

export default Pricing;
