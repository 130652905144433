import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  MuiThemeProvider,
  createMuiTheme,
  responsiveFontSizes
} from "@material-ui/core";
import Team from "./components/Team";
import Pricing from "./components/Pricing";
import Contact from "./components/Contact";
import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import Faq from "./components/Faq";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#222222",
      sub: "lightgray",
      darkGrey: "#484848",
      contrastText: "#f5f5f5"
    },
    secondary: {
      main: "#3AC9D7"
    }
  },
  grayBorder: "1px solid rgba(0,0,0,0.0975)",
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "'Open Sans', sans-serif",
        color: "black"
      },
      h6: {
        fontFamily: "'Open Sans', sans-serif"
      },
      h5: {
        fontFamily: "'Open Sans', sans-serif"
      },
      h4: {
        fontFamily: "'Open Sans', sans-serif"
      },
      h3: {
        fontFamily: "'Open Sans', sans-serif"
      },
      h2: {
        fontFamily: "'Open Sans', sans-serif"
      },
      paragraph: {
        fontFamily: "'Open Sans', sans-serif"
      },
      body1: {
        fontFamily: "'Open Sans', sans-serif"
      },
      body2: {
        fontFamily: "'Open Sans', sans-serif"
      },
      caption: {
        fontFamily: "'Open Sans', sans-serif"
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: "'Open Sans', sans-serif"
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: "#f5f5f5"
      }
    },
    MuiButton: {
      contained: {
        boxShadow: "0px 0px 0px 0px"
      },
      root: {
        fontFamily: "'Open Sans', sans-serif"
      }
    },
    MuiTab: {
      root: {
        fontFamily: "'Open Sans', sans-serif"
      }
    }
  }
});
theme = responsiveFontSizes(theme);

function App() {
  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <Router>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/terms-of-service" component={TermsOfService} />
            <Route exact path="/sign-in" component={SignIn} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/faq" component={Faq} />
          </Switch>
          <Footer />
        </Router>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
