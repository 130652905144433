import React, { useEffect } from "react"
import BasicWhiteComp from "../../utils/BasicWhiteComp";
import { Link } from 'react-router-dom';
import { useTheme, makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import BasicWhite from "../../utils/BasicWhite";
import CompWrapperWidth from "../../utils/CompWrapperWidth";
import NarrowWidthWrap from "../../utils/NarrowWidthWrap";
const useStyles = makeStyles(theme => ({
  basicWhiteCompSubtext: {

    color: theme.palette.primary.sub,
    width: '55%',
    [theme.breakpoints.down("sm")]: {
      width: '60%'
    },
    [theme.breakpoints.down("xs")]: {
      width: '90%',
      textAlign: 'center',
    },
  },
  faqMainDiv: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down("xs")]: {
      alignItems: 'center'
    },
  },
  qAndADiv: {
    [theme.breakpoints.up("lg")]: {
      width: '100%',
      columnCount: 2,
      columnGap: 100,
    },
    [theme.breakpoints.down("md")]: {
      width: '75%',
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 'auto',
      width: '90%'
    },
  },
  faqTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.darkGrey,
    fontWeight: 'bold'
  },
  titleSubtitleDiv: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      alignItems: 'center'
    },
  },
  questionDiv: {

  }
}));
const questions = [
  {
    q: "Customer Service",
    a: "At vero eos et accusamus et iusto odio digniss imos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolore s et quas molestias excepturi sint occaecati cupiditate"
  },
  {
    q: "My second question is this?",
    a: "At vero eos et accusamus et iusto odio digniss imos ducimus qui blanditiis praesentium voluptatum deleniti atque"
  },
  {
    q: "I guess this is my third question?",
    a: "At vero eos et accusamus et iusto odio digniss imos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolore s et quas molestias excepturi sint occaecati cupiditate"
  },
  {
    q: "This is my fourth question, right?",
    a: "At vero eos et accusamus et iusto odio digniss imos ducimus qui blanditiis praesentium voluptatum deleniti atque"
  },
  {
    q: "I'll use this question to demonstrate what would happen if the question is really long?",
    a: "At vero eos et accusamus et iusto odio digniss imos ducimus qui blanditiis praesentium voluptatum deleniti atque"
  },
  {
    q: "My second question is this?",
    a: "At vero eos et accusamus et iusto odio digniss imos ducimus qui blanditiis praesentium voluptatum deleniti atque"
  }
]
const Faq = () => {
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }, []);
  const classes = useStyles()
  const theme = useTheme()
  return (
    <BasicWhite>
      <NarrowWidthWrap>
        <div className={classes.faqMainDiv}>
          <div className={classes.titleSubtitleDiv} >
            <Typography variant="h3" className={classes.faqTitle} >
              Frequently Asked Questions
          </Typography>
            <Typography variant="h6" className={classes.basicWhiteCompSubtext} >
              If you have any other questions, please don't hesitate to <Link to="/contact" style={{ textDecoration: 'none', color: theme.palette.secondary.main }}>Contact Us</Link>
            </Typography>

          </div>
          <div className={classes.qAndADiv}>
            {
              questions.map((item) => {
                return <div className={classes.questionDiv}>
                  <Typography variant="h4" style={{ color: theme.palette.primary.darkGrey, marginBottom: theme.spacing(3) }}>
                    {item.q}
                  </Typography>
                  <Typography variant="h6" style={{ color: theme.palette.primary.sub, marginBottom: theme.spacing(8) }}>
                    {item.a}
                  </Typography>
                </div>
              })
            }
          </div>
        </div>
      </NarrowWidthWrap>
    </BasicWhite>
  )
}

export default Faq